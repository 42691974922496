<template>
    <div v-if="resourceFile" class="amp-video_media_box" :style="style">
        <div :class="viewportClasses">
            <!-- don't use v-img here, it fails to display cropped images  -->
            <img
                v-if="isImage"
                :src="resourceFile.filename"
                alt=""
                @error="setNoFile"
            />
            <video
                v-if="isVideo"
                ref="video"
                loop
                muted
                autoplay
                disablePictureInPicture
                :poster="videoPreview"
                :src="videoUrl"
                @error="setNoFile"
            >
                Your browser does not support HTML Video. Please update your
                browser version.
            </video>
        </div>
    </div>
    <div v-else class="amp-video_media_box-nofile"></div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Watch } from '@/utils/decorators';
import type { CSSProperties } from 'vue/types/jsx';

import ResourceFile, {
    ImageDetails,
    VideoDetails,
    MediaType
} from '@/mixins/ResourceFile';

import type { VideoMediaBox } from '@/types/Video';

const AVideoMediaBoxProps = Vue.extend({
    name: 'AVideoMediaBox',
    props: {
        box: {
            type: Object as PropType<VideoMediaBox>,
            required: true
        }
    }
});

@Component
export default class AVideoMediaBox extends mixins(
    AVideoMediaBoxProps,
    ResourceFile
) {
    $refs!: {
        video: HTMLVideoElement;
    };

    get resourceFile() {
        return this.getResourceFile(this.box);
    }

    get videoUrl() {
        return (this.resourceFile as VideoDetails)?.url || '';
    }

    get videoPreview() {
        return (this.resourceFile as VideoDetails)?.preview || '';
    }

    get isVideo() {
        return this.resourceFile?.type === MediaType.Video;
    }

    get isImage() {
        return this.resourceFile?.type === MediaType.Image;
    }

    get hasOverlay() {
        return this.box?.overlay_background;
    }

    get style(): CSSProperties {
        const style: CSSProperties = {};
        if (this.box?.backdrop_color) {
            let color = '';
            let opacity = '0';
            // transparent 1x1 image is_system_media
            if (
                !(
                    this.box.media_resource.media_file.is_system_media &&
                    this.box.media_resource.media_file.width === 1
                )
            ) {
                [color, opacity] = this.box.backdrop_color.split('@');
            }

            style['--box-drop-color'] = color;
            style['--box-drop-opacity'] = opacity ? Number(opacity || 0) : 1;
        }

        if (this.box?.overlay_background) {
            style['--box-overlay-background'] = this.box.overlay_background;
        }

        return style;
    }

    get viewportClasses() {
        const classes: string[] = ['amp-media-viewport'];

        if (this.hasOverlay) {
            classes.push('with-overlay');
        }

        return classes.join(' ');
    }

    @Watch('resourceFile')
    onResourceFileChange(resourceFile: ImageDetails | VideoDetails | null) {
        if (resourceFile?.type === MediaType.Video && this.$refs.video) {
            this.$refs.video.load();
        }
    }

    setNoFile() {
        this.$el.classList.add('amp-video_media_box-nofile');
    }
}
</script>

<style lang="scss">
.amp-video_media_box {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &:after {
        /* backdrop_color box */
        content: '';
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--box-drop-color);
        opacity: var(--box-drop-opacity);
        z-index: -1;
    }

    .amp-media-viewport {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .with-overlay:after {
        /* overlay_background media box */
        content: '';
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--box-overlay-background);
    }

    img,
    video {
        width: 100%;
        height: 100%;
    }
}

.amp-video_media_box-clickable {
    cursor: pointer;

    &:hover {
        background: $vuetify-grey;
    }

    .amp-media-viewport,
    .amp-video_media_box-nofile {
        transition: all 0.2s ease;
        cursor: pointer;

        &:after {
            content: '';
            transition: 0.2s ease;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            background: var(--box-overlay-background);
        }
    }

    .amp-media-viewport:hover:after {
        opacity: 0.3;
        background: repeating-linear-gradient(
            -45deg,
            transparent,
            transparent 30px,
            white 30px,
            white 40px
        );
    }
}

.amp-video_media_box-nofile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.amp-video_media_box,
.amp-video_media_box-clickable {
    .amp-video_media_box-nofile {
        border: 3px solid $error;

        &:hover {
            background-color: $error;

            &:after {
                opacity: 0.3;
            }
        }
    }
}
</style>
