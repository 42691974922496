<template>
    <span>
        <v-menu
            v-if="actionable"
            v-model="active"
            :position-x="x"
            :position-y="y"
            :attach="parentElement"
            absolute
            nudge-left="5"
            nudge-top="5"
        >
            <div ref="container" class="pa-1 black d-flex">
                <a-replace-media-activator
                    v-if="isReplaceable"
                    @replace="replace"
                />
                <a-crop-media-activator v-if="isCroppable" @crop="crop" />
            </div>
        </v-menu>
        <a-replace-media-handler
            v-if="actionable"
            ref="replacer"
            :box="box"
            v-on="$listeners"
        />
        <a-crop-media-handler
            v-if="actionable"
            ref="cropper"
            :box="box"
            v-on="$listeners"
        />
    </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import AReplaceMedia from './AReplaceMedia.vue';
import ACropMediaActivator from './ACropMediaActivator.vue';
import ACropMediaHandler from './ACropMediaHandler.vue';
import AReplaceMediaActivator from './AReplaceMediaActivator.vue';
import AReplaceMediaHandler from './AReplaceMediaHandler.vue';

import { MediaModuleMedia } from '@/mixins';

import type { MediaBoxActionOptions } from '@/mixins/MediaModule/MediaBoxActions';

const ABoxActionsProps = Vue.extend({
    name: 'ABoxActions',
    props: {
        options: {
            type: Object as PropType<MediaBoxActionOptions>,
            default() {
                return {
                    active: false,
                    box: null,
                    e: {
                        clientX: 0,
                        clientY: 0,
                        width: 0,
                        height: 0
                    }
                };
            }
        },
        readonly: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AReplaceMedia,
        ACropMediaActivator,
        ACropMediaHandler,
        AReplaceMediaActivator,
        AReplaceMediaHandler
    }
})
export default class ABoxActions extends mixins(
    ABoxActionsProps,
    MediaModuleMedia
) {
    $refs!: {
        cropper: InstanceType<typeof ACropMediaHandler>;
        replacer: InstanceType<typeof AReplaceMediaHandler>;
        container: InstanceType<typeof HTMLDivElement>;
    };

    dimensions = {
        width: 0,
        height: 0
    };

    parentElement: HTMLElement | null = null;

    get actionable() {
        return Boolean(
            !this.readonly &&
                this.box &&
                this.mediaIsChangeable(this.box) &&
                (this.isCroppable || this.isReplaceable)
        );
    }

    get isCroppable() {
        return (
            this.resource?.media_file?.type === 0 &&
            this.fileSrc &&
            !this.isBlank
        );
    }

    get isReplaceable() {
        return !this.box?.video_media_box?.is_logo_box;
    }

    get isBlank() {
        if (this.box?.video_media_box?.is_logo_box) {
            const [blankMediaFileId]: number[] = JSON.parse(
                this.box.video_media_box?.media_file_ids || '[]'
            );

            if (blankMediaFileId) {
                return (
                    this.box.video_media_box.media_resource.media_file_id ===
                    blankMediaFileId
                );
            }
        }

        return false;
    }

    get resource() {
        return this.box?.video_media_box?.media_resource;
    }

    get fileSrc() {
        return this.resource?.media_file?.relative_filename || '';
    }

    get x() {
        if (this.options.e.width && this.options.e.clientX) {
            if (
                this.options.e.width - this.options.e.clientX <
                this.dimensions.width
            ) {
                return this.options.e.width - this.dimensions.width;
            }
        }

        return this.options.e.clientX;
    }

    get y() {
        if (this.options.e.height && this.options.e.clientY) {
            if (
                this.options.e.height - this.options.e.clientY <
                this.dimensions.height
            ) {
                return this.options.e.height - this.dimensions.height;
            }
        }

        return this.options.e.clientY;
    }

    get box() {
        return this.options.box;
    }

    get active() {
        return this.options.active;
    }

    set active(value: boolean) {
        this.options.active = value;
    }

    mounted() {
        if (this.$el) {
            this.parentElement = this.$el.parentElement;
        }
    }

    @Watch('options')
    onOptionsChange() {
        if (this.options.active) {
            this.options.active = false;

            setTimeout(() => {
                this.options.active = true;

                this.$nextTick(() => {
                    this.updateDimensions();
                });
            }, 50);
        }
    }

    crop() {
        this.$refs.cropper.crop();
    }

    replace() {
        this.$refs.replacer.replace();
    }

    updateDimensions() {
        if (this.$refs.container) {
            this.dimensions = this.$refs.container.getBoundingClientRect();
        }
    }
}
</script>
