import type { Announcement } from './Announcement';
import type { MediaFile } from './MediaFile';
import type { MediaResource } from './MediaResource';
import type { User } from './User';

export type VideoBoxType =
    | 'video_media_box'
    | 'video_color_box'
    | 'video_text_box'
    | 'video_zoom_box';

export enum VideoTextBoxAlignX {
    Center = 0,
    Left = 1,
    Right = 2,
    JUSTIFY = 3
}

export enum VideoStatus {
    Draft = 7,
    ToRender = 0,
    Rendering = 1,
    Rendered = 2,
    Approved = 3,
    Unapproved = 4,
    AwaitingLoResRendering = 5,
    LoResRenderReady = 6,
    RenderingPreview = 8,
    Error = 9
}

export enum VideoTemplateType {
    NORMAL = 0,
    AUTOGEN = 1,
    LINEAR_GRADIENT_ONE = 2
}

export interface Video {
    announcement_id: number;
    announcement: Announcement | null;
    created: number;
    id: number;
    is_editable: boolean;
    is_live: boolean;
    is_publishable: boolean;
    notification_published_sent: number | null;
    publish_time: number | null;
    status_string: string;
    status: VideoStatus;
    summary: string;
    title: string;
    user: User;
    user_id: number;
    video_template_id: number;
    video_template: VideoTemplate;
    is_new?: boolean;
}

export interface VideoPr extends Video {
    id: number;
    video_voice_id: number | null;
    video_audio_clip_id: number | null;
    description: string;
    tags_array: string[];
    add_ident: boolean;
    preview_src: string | null;
    preview_available: boolean;
    can_switch_editor: boolean;
    downloadable: boolean;
}

export interface VideoTemplate {
    description: string;
    height: number;
    id: number;
    image: null;
    is_premium: boolean;
    is_private: boolean;
    module_type: number;
    name: string;
    parent_template_id: number | null;
    release_month: number | null;
    video_audio_clips: VideoAudioClip[];
    video_boxes: VideoBox[];
    video_voices: VideoVoice[];
    width: number;
    type: number;
}

export interface VideoAudioClip {
    filename: string;
    id: number | null;
    is_premium: boolean;
    is_system_media: boolean;
    route: string;
}

export interface VideoBox {
    border_color: null;
    border_opacity: number;
    border_width: number;
    border_radius: string | null;
    description: string;
    duration: number;
    fade_in: boolean;
    fade_out: boolean;
    height: number;
    id: number;
    start_frame: number;
    type: VideoBoxType;
    ui_group: number;
    video_color_box: VideoColorBox | null;
    video_media_box: VideoMediaBox | null;
    video_template_id: number;
    video_text_box: VideoTextBox | null;
    video_zoom_box: VideoZoomBox | null;
    width: number;
    wipe_in_horizontal: number;
    wipe_in_vertical: number;
    wipe_out_horizontal: number;
    wipe_out_vertical: number;
    x: number;
    y: number;
    alternate_x: number | null;
    alternate_y: number | null;
    alternate_width: number | null;
    alternate_height: number | null;
    original_x: number;
    original_y: number;
    original_width: number;
    original_height: number;
}

export interface VideoColorBox {
    id: number;
    video_box_id: number;
    color: string;
    opacity: number;
    backdrop_filter: string | null;
}

export interface VideoMediaBox {
    id: number;
    video_box_id: number;
    media_file_ids: string | null;
    allow_user_images: number;
    allow_stock_media: boolean;
    allowed_media_types: number;
    preserve_aspect_ratio: boolean;
    zoompan: boolean;
    media_files: MediaFile[];
    media_resource: MediaResource;
    backdrop_color: string | null;
    is_optional: boolean;
    is_logo_box: boolean;
    overlay_background: string | null;
}

export interface VideoTextBox {
    id: number;
    video_box_id: number;
    video_font_id: number;
    font_size: number;
    color: string;
    max_length: number;
    align_x: VideoTextBoxAlignX;
    center_y: boolean;
    draw_incrementally: boolean;
    to_upper: boolean;
    generate_voiceover: boolean;
    example_text: string | null;
    voiceover_start_frame: number | null;
    voiceover_duration: number | null;
    backdrop_color: string | null;
    emphasis_color: string | null;
    video_font: VideoFont;
    emphasis_font: VideoFont | null;
    video_caption: VideoCaption;
    outline_size: number;
    use_dynamic_font_size: boolean;
    line_spacing: number;
}

export interface TextEmphasis {
    font: VideoFont;
    color: string | null;
    offset: number;
}

export interface VideoZoomBox {
    id: number;
    video_box_id: number;
    zoom_factor: number;
}

export interface VideoFont {
    id: number;
    filename: string;
    css_name: string;
    relative_route: string;
}

export interface VideoCaption {
    id: number;
    video_pr_id: number | null;
    video_text_box_id: number;
    text: string;
    voiceover_status: number | null;
    slideshow_id: number | null;
    infographic_id: number | null;
}

export interface VideoVoice {
    id: number | null;
    voice_name: string;
    is_premium: boolean;
    voice_id: string;
    video_language_id: number;
    type: number;
    video_language: VideoLanguage;
    route: string;
}

export interface VideoLanguage {
    id: number;
    name: string;
    video_font_id: number | null;
}
// types accepted by server
export interface AcceptedMediaResource {
    crop_height: number | null;
    crop_width: number | null;
    crop_x: number | null;
    crop_y: number | null;
    id: number;
    media_file_id: number;
    video_media_box_id: number;
}

export interface AcceptedVideoCaption {
    id: number;
    text: string;
    video_text_box_id: number;
    voiceover_status: number | null;
}

export interface MediaStyle {
    top: number;
    left: number;
    width: number;
    height: number;
}

export interface MediaAspectRatio {
    width: number;
    height: number;
}
